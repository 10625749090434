<template>
  <div class="order-detail bsbb">
    <!-- <div class="top df aic">
        <van-icon name="arrow-left" class="font-size-24" @click="$router.push('/user1')" />
        <input type="text" placeholder="搜索我的订单" class="font-size-24" />
      </div> -->
    <div class="main-app df fdc bsbb">
      <div class="df aic">
        <van-icon size="16" name="arrow-left" class=" mr20" @click="$router.push('/user1')" />
        <div class="df aic jcsb">
          <van-tabs class="fn" line-height="3px" :ellipsis="false" duration="0.1" v-model="active" background="#f2f2f2"
            color="rgba(85, 187, 73, 1)">
            <van-tab :name="item.path" v-for="item in navList" :key="item.path" :title="item.title"
              :to="item.path"></van-tab>
          </van-tabs>
        </div>
      </div>
      <router-view class="f1 ofh" style="padding: 20px 0"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: this.$route.path,
      navList: [
        { title: "全部", path: "/all/allOrder" },
        { title: "待付款", path: "/all/obligation2" },
        { title: "待發貨", path: "/all/waitReceiving" },
        { title: "待評價", path: "/all/waitCommend" },
        // { title: "退货/售后", path: "/all/returnOrder" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tab__text {
  // margin-right: 20px;
  font-size: 24px;
  padding: 10px;
}

::v-deep .van-tab {
  width: 150px;
}

.order-detail {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  padding: 40px 30px;
  font-family: "pingfang";

  input {
    height: 50px;
    width: 400px;
    border-radius: 30px;
    outline: none;
    border: none;
    margin-left: 20px;
    text-indent: 16px;
  }

  .main-app {
    background: #f2f2f2;
    padding: 20px 0;

    .van-tabs {
      width: 100%;

      .van-tab {
        width: 25%;
      }
    }
  }
}
</style>